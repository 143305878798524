import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import { KfAnchorLink } from '@klickinc/kf-react-components';
import Image from '@components/utility/Image';
import Hero from '@components/content/hero/Hero';
import Cta from '@components/content/Cta';
//styles
import './styles.scss';

const PrescriptionStartForm = () => {
	return (
		<Layout>
			<Seo title='Prescription Start Form | EMFLAZA® (deflazacort)' description='Download a Prescription Start Form and help get your patients started with EMFLAZA® (deflazacort). View full Prescribing Information & Important Safety Information.' />
			<div className="md:container 2xl:mx-auto page--prescription-start-form xl:pr-48">
				<Hero heroImage='hero-5-2.png' heroImageMobile='hero-5-2-mobile.png' breadcrumbs={['Getting Started', 'Prescription Start Form']} title={<span>Help Patients Get Started</span>} addedClass='py-2' />

				<div className="max-w-4xl px-8 text-emflaza-blue-600 md:px-0">
					{/* small section starts */}
					<div className='flex flex-wrap gap-5 mt-16 md:gap-10 md:mt-32 lg:mt-52 md:mb-24'>
						<h2 className='flex-auto text-xl font-black uppercase md:flex-1 md:text-4xl'>
							<span className='max-w-[200px] md:max-w-none'>Completing The prescription start form</span>
						</h2>
						<div className='flex flex-col flex-1 space-y-5 text-center xl:min-w-[300px]'>
							<p className='text-left'>Before getting started, download the Prescription Start Form and then follow the steps below.</p>
							<KfAnchorLink url='/prescription-start-form.pdf' download="prescription-start-form" linkClass='btn px-5 py-6 md:py-6' target="_blank">Download Prescription Start Form</KfAnchorLink>
						</div>
					</div>
					{/* small section ends */}

					{/* SECTION#1 START */}
					<div className="relative">
						<div className="absolute section-bg-1 -left-8 -right-8 top-48 md:-top-24 md:left-0 md:right-auto z-negative">
							<Image imageName='section-bg1-5-2.png' imageNameMobile='section-bg1-5-2-mobile.png' alt=""/>
						</div>

						<div className="flex flex-wrap items-end justify-center mt-10 space-y-20 md:space-y-0 md:mb-14">
							<div className='flex-auto text-center md:flex-1 max-w-[200px] md:max-w-none'>
								<Image imageName='section-icon-1.png' imageNameMobile='section-icon-1-mobile.png' alt="EMFLAZA® (deflazacort) Prescription Start Form" addedClass="max-w-[17.625rem] my-0 mx-auto"/>
							</div>

							<div className='flex-1'>
								<h2 className='flex-auto text-xl font-black uppercase md:flex-1 md:text-4xl'>
									<span className='max-w-[200px] md:max-w-none'>For patient and caregivers</span>
								</h2>
								<ul className='mt-5'>
									<li className='flex my-3'>
										<span className='mr-3 uppercase text-emflaza-blue-100'>Step 1</span>
										<p className='flex-1'>
											Download the <span className='font-black'>Prescription Start Form</span>
										</p>
									</li>
									<li className='flex my-3'>
										<span className='mr-3 uppercase text-emflaza-blue-100'>Step 2</span>
										<p className='flex-1'>
											Complete patient and insurance information on the patient side of the form
										</p>
									</li>
									<li className='flex my-3'>
										<span className='mr-3 uppercase text-emflaza-blue-100'>Step 3</span>
										<p className='flex-1'>
											Having the patient/guardian sign at the bottom of the form
											will prevent delays in processing the prescription
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					{/* SECTION#1 END */}


					{/* SECTION#2 START */}
					<div className="relative">
						<div className="absolute section-bg-2 -left-8 -right-8 top-64 md:top-0 md:left-0 md:right-auto z-negative">
							<Image imageName='section-bg2-5-2.png' imageNameMobile='section-bg2-5-2-mobile.png' alt=""></Image>
						</div>

						<div className="flex flex-wrap min-h-[840px] space-y-20 md:space-y-0 justify-center items-center mb-14 mt-10 md:min-h-[400px] md:py-8 xl:mt-24">
							<div className='flex-auto text-center md:flex-1 max-w-[200px] md:max-w-none'>
								<Image imageName='section-icon-2.png' imageNameMobile='section-icon-2-mobile.png' alt="EMFLAZA® (deflazacort) Prescription Start Form" addedClass="max-w-[17.625rem] my-0 mx-auto"/>
							</div>

							<div className='flex-1 for-prescriber-only-overrides'>
								<h2 className='flex-auto text-xl font-black uppercase md:flex-1 md:text-4xl'>
									<span className='max-w-[200px] md:max-w-none'>For the prescriber</span>
								</h2>
								<ul className='mt-5'>
									<li className='flex my-3'>
										<span className='mr-3 uppercase text-emflaza-blue-100'>Step 1</span>
										<p className='flex-1'>
											Sign both prescriptions (Primary and Bridge) in the Prescription Information section
											of the form. This will ensure your patient has ongoing access to EMFLAZA
										</p>
									</li>
									<li className='flex my-3'>
										<span className='mr-3 uppercase text-emflaza-blue-100'>Step 2</span>
										<p className='flex-1'>
											Sign at the bottom of the form where it says “Prescriber Authorization Signature”
										</p>
									</li>
									<li className='flex my-3'>
										<span className='mr-3 uppercase text-emflaza-blue-100'>Step 3</span>
										<div className='flex-1'>
											<p>
												PTC <em>Cares</em> may also need additional documentation, such as:
											</p>
											<ul className='bulleted-list'>
												<li>Statement of medical necessity</li>
												<li>ICD-10 code</li>
												<li>Chart notes or dosage changes</li>
												<li>
													Patient’s prior medical history of taking prednisone
												</li>
											</ul>
										</div>
									</li>
								</ul>

								<div className='dw-information'>
									<p>Take a look at the dispense as written (DAW) guidelines for prescribing EMFLAZA in your state.</p>
									<div className='text-center md:text-left'>
									<KfAnchorLink url='/dispense-as-written-guide.pdf' target="_blank" linkClass='z-0 btn my-3 uppercase'>Download</KfAnchorLink>
								</div>
								</div>
							</div>
						</div>
					</div>
					{/* SECTION#2 END */}

					{/* SECTION#3 START */}
					<div className="relative">
						<div className="absolute section-bg-3 -left-8 -right-8 -top-12 md:-top-6 md:left-4 md:right-auto z-negative">
							<Image imageName='section-bg3-5-2.png' imageNameMobile='section-bg3-5-2-mobile.png' alt=""></Image>
						</div>

						<div className="flex flex-wrap min-h-[200px] justify-end gap-3 md:gap-10 items-center my-32 md:my-0">
							<div className='flex-auto md:flex-1 text-center max-w-[100px]'>
								<Image imageName='section-icon-3.png' alt="PTC Cares™"></Image>
							</div>
							<h3 className='font-bold text-2xl text-emflaza-blue-400 flex-1 max-w-[600px]'>Once patient enrollment has begun, PTC <span className='italic'>Cares</span><span>&trade;</span> manages each step of the prescription process</h3>
						</div>
					</div>
					{/* SECTION#3 END */}

					<div className="flex flex-wrap space-y-5 md:space-y-0 md:space-x-5 mb-8 mt-14 md:mt-36 xl:mt-14">
						<Cta ctaUrl='/sign-up' iconUrl={'cta-icon-sign-up.png'} ctaText={<span>Sign up for <br /> more information</span>} addedClass='cta flex-1' iconAddedClass='max-w-[74px]'/>
						<Cta ctaUrl='/resources' iconUrl={'cta-icon-heart.png'} ctaText={<span>Patient Access <br /> & Support</span>} addedClass='cta flex-1' iconAddedClass='max-w-[80px]'/>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default PrescriptionStartForm;
